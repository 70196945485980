import * as React from 'react';
import classNames from 'classnames';
import { IPopupPageProps } from '../PopupPage.types';
import FillLayers from '../../FillLayers/viewer/FillLayers';
import style from './style/PopupPage.scss';

const PopupPage: React.FC<IPopupPageProps> = ({
  id,
  pageDidMount,
  fillLayers,
  onClick,
  children,
}) => (
  <div
    id={id}
    className={classNames(style.root, onClick && style.clickable)}
    ref={pageDidMount}
  >
    <div {...(onClick && { onClick })}>
      <FillLayers {...fillLayers} />
    </div>
    <div className={style.lightboxLayer}>{children()}</div>
  </div>
);

export default PopupPage;
